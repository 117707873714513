.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10% 1.5rem 1.5rem 1.5rem;
  }
  
  .backLink {
    display: inline-flex;
    align-items: center;
    color: #6b7280;
    font-size: 0.875rem;
    margin-bottom: 2rem;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .backLink:hover {
    color: #3b82f6;
  }
  
  .backIcon {
    margin-right: 0.5rem;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .meta {
    margin-bottom: 2rem;
  }
  
  .category {
    display: inline-flex;
    padding: 0.25rem 0.75rem;
    background-color: #f3f4f6;
    color: #4b5563;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #111827;
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }
  
  .infoBar {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  
  .authorContainer {
    display: flex;
    align-items: center;
  }
  
  .avatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.75rem;
  }
  
  .authorInfo {
    display: flex;
    flex-direction: column;
  }
  
  .authorName {
    font-weight: 600;
    color: #111827;
    font-size: 0.875rem;
  }
  
  .publishDate {
    color: #6b7280;
    font-size: 0.75rem;
  }
  
  .readingTime {
    display: flex;
    align-items: center;
    color: #6b7280;
    font-size: 0.875rem;
  }
  
  .clockIcon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
  }
  
  .featuredImage {
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: cover;
    border-radius: 0.5rem;
    margin-bottom: 2.5rem;
  }
  
  .content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contentBody {
    font-size: 1.125rem;
    line-height: 1.8;
    color: #1f2937;
  }
  
  /* Rich text content styling */
  .contentBody h2 {
    font-size: 1.875rem;
    font-weight: 600;
    color: #111827;
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
  }
  
  .contentBody h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .contentBody p {
    margin-bottom: 1.5rem;
  }
  
  .contentBody a {
    color: #2563eb;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
  
  .contentBody a:hover {
    color: #1d4ed8;
  }
  
  .contentBody ul,
  .contentBody ol {
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
  }
  
  .contentBody li {
    margin-bottom: 0.5rem;
  }
  
  .contentBody blockquote {
    border-left: 4px solid #e5e7eb;
    padding-left: 1rem;
    font-style: italic;
    color: #4b5563;
    margin: 1.5rem 0;
  }
  
  .contentBody pre {
    background-color: #f3f4f6;
    padding: 1rem;
    border-radius: 0.375rem;
    overflow-x: auto;
    margin: 1.5rem 0;
  }
  
  .contentBody code {
    font-family: monospace;
    background-color: #f3f4f6;
    padding: 0.125rem 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.875em;
  }
  
  .contentBody img {
    max-width: 100%;
    height: auto;
    border-radius: 0.375rem;
    margin: 1.5rem 0;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid #e5e7eb;
  }
  
  .tag {
    display: inline-flex;
    padding: 0.25rem 0.75rem;
    background-color: #f3f4f6;
    color: #4b5563;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .relatedPostsSection {
    margin-top: 4rem;
    padding-top: 3rem;
    border-top: 1px solid #e5e7eb;
  }
  
  .sectionTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 1.5rem;
  }
  
  .relatedPosts {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .relatedPostCard {
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    background-color: #fff;
  }
  
  .relatedPostCard:hover {
    transform: translateY(-4px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .relatedPostImage {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  
  .relatedPostContent {
    padding: 1.25rem;
  }
  
  .relatedPostCategory {
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #6b7280;
    margin-bottom: 0.5rem;
  }
  
  .relatedPostTitle {
    font-size: 1.125rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 0.75rem;
    line-height: 1.4;
  }
  
  .relatedPostMeta {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    color: #6b7280;
  }
  
  .relatedPostAuthor {
    display: flex;
    align-items: center;
  }
  
  .relatedPostAvatar {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  
  .relatedPostDate {
    margin-left: auto;
  }
  
  /* Loading and error states */
  .loadingContainer, 
  .errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    text-align: center;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .loadingSpinner {
    width: 3rem;
    height: 3rem;
    border: 3px solid #e5e7eb;
    border-top-color: #3b82f6;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
    margin-bottom: 1rem;
  }
  
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  
  .errorIcon {
    color: #ef4444;
    margin-bottom: 1rem;
    width: 3rem;
    height: 3rem;
  }
  
  .errorMessage {
    font-size: 1.125rem;
    font-weight: 500;
    color: #1f2937;
    margin-bottom: 1.5rem;
  }
  
  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    font-weight: 500;
    border-radius: 0.375rem;
    transition: background-color 0.2s ease;
    text-decoration: none;
  }
  
  .primaryButton {
    background-color: #3b82f6;
    color: white;
  }
  
  .primaryButton:hover {
    background-color: #2563eb;
  }
  
  .secondaryButton {
    background-color: #e5e7eb;
    color: #1f2937;
  }
  
  .secondaryButton:hover {
    background-color: #d1d5db;
  }
  
  .actionButtons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 3rem;
  }


  @media (max-width: 768px) {
    .container {
        padding: 25% 1.5rem 1.5rem 1.5rem;
    }
  }