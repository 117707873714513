.coffeeLoader {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
}

.coffeeLoader h1 {
  color: #000;
  font-size: 1rem;
  text-align: center;
}

.coffeeLoader p {
  color: #000;
  font-size: 0.9rem;
}
