.card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-width: 384px;
  height: 100%;
  gap: 20px;
  border-radius: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  padding-bottom: 16px;
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.card:hover .title {
  color: #2966FF;
  cursor: pointer;
}

.imageContainer {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  overflow: hidden;
  border-radius: 16px 16px 0 0;
  cursor: pointer;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card:hover .image {
  transform: scale(1.05);
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  padding: 0 16px;
}

.category {
  font-size: 14px;
  font-weight: 600;
  color: #2966FF;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.title {
  color: #181D27;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  transition: color 0.2s ease;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.45;
  letter-spacing: 0%;
}

.arrow {
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  color: #181D27;
  flex-shrink: 0;
  transition: color 0.2s ease, transform 0.2s ease;
}

.card:hover .arrow {
  color: #2966FF;
  transform: translateX(3px);
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0%;
  color: rgba(83, 88, 98, 1);
  flex-grow: 1;
  margin-bottom: 16px;
}

.authorSection {
  display: flex;
  align-items: center;
  margin-top: auto;
  padding: 0px 16px;
}

.authorImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 12px;
}

.authorInfo {
  display: flex;
  flex-direction: column;
}

.authorName {
  font-weight: 600;
  font-size: 14px;
  color: #222;
}

.date {
  font-size: 14px;
  color: #777;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .card {
    max-width: 340px;
  }
}

@media (max-width: 992px) {
  .card {
    max-width: 320px;
  }
}

@media (max-width: 768px) {
  .card {
    max-width: 100%;
  }
  
  .title {
    font-size: 18px;
    line-height: 1.4;
  }
  
  .description {
    font-size: 15px;
    line-height: 1.4;
  }
  
  .content {
    gap: 6px;
  }
}

@media (max-width: 480px) {
  .imageContainer {
    padding-bottom: 65%; /* Slightly taller images on mobile */
    cursor: pointer;
  }
  
  .title {
    font-size: 16px;
  }
  
  .description {
    font-size: 14px;
    margin-bottom: 12px;
  }
  
  .authorImage {
    width: 36px;
    height: 36px;
  }
  
  .category {
    font-size: 12px;
  }
  
  .authorName, .date {
    font-size: 13px;
  }
}