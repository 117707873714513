.stats-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 64px 20px;
    text-align: center;
    margin-top: 120px;
}

.stats-title {
    font-size: 60px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 60px;
}

.text-dark {
    color: #1a1a1a;
}

.text-blue {
    color: #0088ff;
}

.stats-grid {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 40px;
}

.stat-card {
    flex: 1;
    max-width: 300px;
    padding: 24px;
    border-radius: 12px;
    background: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
}

.stat-card:hover {
    transform: translateY(-5px);
}

.counter {
    display: block;
    font-size: 48px;
    font-weight: 700;
    color: #0088ff;
    margin-bottom: 12px;
}

.stat-label {
    font-size: 16px;
    color: #666;
    line-height: 1.4;
}

/* Separator lines between stats */
.stat-card:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    height: 60%;
    width: 1px;
    background: #eee;
}

/* Responsive design */
@media (max-width: 768px) {
    .stats-title {
        font-size: 32px;
    }

    .stats-grid {
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    .stat-card {
        width: 100%;
    }

    .stat-card:not(:last-child)::after {
        display: none;
    }

    .counter {
        font-size: 36px;
    }

    .stats-container {

        margin-top: 0;
    }

}