.card_container {
    width: 100%;
    height: 720px;
    gap: 64px;
    border-radius: 16px;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    background-size: cover;
    position: relative;
    overflow: hidden;
  }
  
  .cntnt_box {
    width: 100%;
    height: auto;
    min-height: 220px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 8px;
    border-top-width: 1px;
    padding: 32px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
  }
  
  .blog_heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    gap: 16px;
  }
  
  .blog_heading h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0%;
    color: #fff;
    flex: 1;
  }
  
  .blog_heading img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  
  .blog_excerpt {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    color: #fff;
    margin-bottom: 16px;
  }
  
  .blog_details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .blog_author_box {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }
  
  .blog_dt_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0%;
    color: #fff;
    flex-shrink: 0;
  }
  
  .blog_dt_card img {
    width: 32px;
    height: 32px;
    opacity: 0.8;
    border-radius: 200px;
    border-width: 0.75px;
    object-fit: cover;
  }
  
  .blog_category {
    padding: 2px 10px;
    border: 1.5px solid #fff;
    border-radius: 16px;
    text-align: center;
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
    .card_container {
      height: 640px;
    }
    
    .cntnt_box {
      padding: 28px;
    }
  }
  
  @media (max-width: 992px) {
    .card_container {
      height: 560px;
    }
    
    .blog_heading h2 {
      font-size: 22px;
      line-height: 30px;
    }
    
    .blog_excerpt {
      font-size: 15px;
      line-height: 22px;
    }
  }
  
  @media (max-width: 768px) {
    .card_container {
      height: 480px;
      gap: 32px;
    }
    
    .cntnt_box {
      padding: 24px;
      min-height: 200px;
    }
    
    .blog_heading h2 {
      font-size: 20px;
      line-height: 28px;
    }
    
    .blog_excerpt {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px;
    }
    
    .blog_dt_card {
      font-size: 13px;
    }
    
    .blog_dt_card img {
      width: 28px;
      height: 28px;
    }
  }
  
  @media (max-width: 576px) {
    .card_container {
      height: 400px;
      gap: 16px;
    }
    
    .cntnt_box {
      padding: 20px;
      min-height: 180px;
    }
    
    .blog_heading h2 {
      font-size: 18px;
      line-height: 24px;
    }
    
    .blog_heading img {
      width: 20px;
      height: 20px;
    }
    
    .blog_excerpt {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    
    .blog_details {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
    
    .blog_dt_card {
      flex-direction: row;
      align-items: center;
    }
    
    .blog_dt_card img {
      width: 24px;
      height: 24px;
    }
    
    .blog_category {
      padding: 1px 8px;
      font-size: 12px;
    }
  }
  
  @media (max-width: 400px) {
    .card_container {
      height: 360px;
    }
    
    .cntnt_box {
      padding: 16px;
    }
    
    .blog_heading h2 {
      font-size: 16px;
      line-height: 22px;
    }
    
    .blog_excerpt {
      -webkit-line-clamp: 2;
    }
  }