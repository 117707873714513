.partner_title {
    font-size: 48px;
}

.partner-container {
    /* display: flex; */
    width: 100%;
    /* flex-direction: row; */
    text-align: center;
}

.partner-logos {
    /* display:flex; */
    /* gap: 24px; */
    width: 100%;
}

.partner-logo-item {
    width: 100%;
}

@media screen and (max-width:768px) {
    .partner-title {
        font-size: 28px !important;
    }

    .partner-logos img {
        width: 180px;
    }
}