.comparison-section {
    margin: 0 auto;
    padding: 64px 20px;
    background: linear-gradient(190deg, #D3EDFF -29.23%, #FFF 46.83%), #FFF;
}

.testimonials-title {
    text-align: center;
    margin-bottom: 30px;
}

.comparison-table-container {
    max-width: 1200px;
    margin: 0 auto;
}

.comparison-table {
    width: 100%;
}

.comparison-header {
    background-color: white;
    box-shadow: 0px 4px 62.5px 0px rgba(0, 0, 0, 0.05);
    margin-bottom: 12px;
    border-radius: 8px;
}

.comparison-header tr {
    display: flex;
}

.comparison-header th {
    display: flex;
    padding: 12px 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.features-header {
    flex: 2;
}

.brand-header,
.others-header {
    flex: 1;
}

.features_txt {
    color: #2B9E50;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    border-radius: 4px;
    background: rgba(169, 235, 169, 0.40);
    padding: 6px 12px;
}

.comparison-body {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 10px 20px 2px rgba(63, 88, 51, 0.05), 0px 17px 20px -5px rgba(63, 88, 51, 0.02);
}

.comparison-body tr {
    display: flex;
}

.feature-comparison {
    flex: 2;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 11px 16px 11px 32px;
    border-right: 1px solid #E9E9ED;
    border-bottom: 1px solid #E9E9ED;
}

.feature-comparison img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.table_icon_d {
    flex: 1;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-right: 1px solid #E9E9ED;
    border-bottom: 1px solid #E9E9ED;
}

.tick_td {
    background: #F5FBFF;
}

/* Tablet styles */
@media screen and (max-width: 768px) {
    .comparison-section {
        padding: 40px 15px;
    }

     .features-header {
       display: flex !important;
       justify-content: flex-start !important;
       padding-left: 10px !important;
    }


    .testimonials-title {
        font-size: 24px;
    }

    .comparison-header th {
        height: 90px;
        padding: 10px 5px;
    }

    .features_txt {
        font-size: 14px;
        padding: 4px 8px;
    }

    .brand-header img {
        max-width: 100%;
        height: auto;
    }

    .feature-comparison {
        font-size: 14px;
        gap: 8px;
        padding: 11px 8px 11px 16px;
    }

    .table_icon_d svg {
        width: 28px;
        height: 28px;
    }
}

/* Mobile styles */
@media screen and (max-width: 480px) {
    .comparison-section {
        padding: 30px 10px;
    }

    .testimonials-title {
        font-size: 28px;
    }

    .comparison-header th {
        height: 60px;
        padding: 8px 4px;
    }

    .features_txt {
        font-size: 12px;
        padding: 3px 6px;
        white-space: nowrap;
    }

    .features-header {
        flex: 1.5;
    }

    .brand-header,
    .others-header {
        flex: 0.75;
        font-size: 12px;
    }

   

    .feature-comparison {
        flex: 1.5;
        font-size: 11px;
        line-height: 1.3;
        gap: 6px;
        padding: 10px 5px 10px 10px;
    }

    .feature-comparison img {
        width: 16px;
        height: 16px;
    }

    .table_icon_d {
        flex: 0.75;
        padding: 10px 5px;
    }

    .table_icon_d svg {
        width: 16px;
        height: 16px;
    }
}

/* Very small screens */
@media screen and (max-width: 350px) {
    .feature-comparison {
        font-size: 10px;
        padding: 8px 4px 8px 8px;
        gap: 4px;
    }

    .feature-comparison img {
        width: 14px;
        height: 14px;
    }

    .table_icon_d svg {
        width: 14px;
        height: 14px;
    }
}