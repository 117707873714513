.landing-container {
    /* min-height: 100vh; */
    background: linear-gradient(to bottom, #f0f7ff 0%, #ffffff 100%);
}

.landing-footer-container {

    background: linear-gradient(to top, #f0f7ff 0%, #ffffff 100%);
}

.main-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 64px 16px;
    position: relative;
}

.gradient_app {
    background: linear-gradient(91deg, #1B89DB 39.83%, #2C3CA9 92.47%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Content styles */
.content {
    position: relative;
    z-index: 10;
    /* max-width: 800px; */
    margin: 0 auto;
    text-align: center;
    margin-top: 90px;
}

.landing-container h1 {
    font-size: 64px;
    font-weight: 700;
    line-height: 1.3;
    /* margin-bottom: 24px; */
}

@media (max-width: 768px) {
    .landing-container h1 {
        font-size: 36px;
    }
}

.text-dark {
    color: #1a1a1a;
}

.text-blue {
    color: #0066ff;
}

.landing-container h2 {
    font-size: 40px;
    font-weight: 700;
    color: #1a1a1a;
    margin-bottom: 32px;
}

@media (max-width: 768px) {
    .landing-container h2 {
        font-size: 30px;
    }
}

.landing-container p {
    font-size: 18px;
    color: #666666;
    margin-bottom: 48px;
    line-height: 1.6;
}

/* Button styles */
.demo-button {
    display: flex;
    align-items: center;
    margin: 0 auto;
    /* gap: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease; */
}

.demo-button:hover {
    background-color: #0052cc;
}

/* Background elements */
.background-elements {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 90px;
}

.floating-card {
    position: absolute;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 4px;
}

.card-content {
    width: 128px;
    height: 64px;
    background-color: #e0e0e00a;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    color: #666666;
    width: 100;
}

.card-content p {
    margin: 0;
}

.card_report_intent {
    background-color: #1bdb652a;
    border-radius: 2px;
    padding: 1px 6px;
}

.card_content_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
    margin-bottom: 4px;
}

.cxd {
    display: flex;
    align-items: center;
    border-radius: 14px;
    background: #E7FFE8;
}

.card-1 {
    top: 40px;
    left: 40px;
    transform: rotate(-6deg);
}

.card-2 {
    top: 80px;
    right: 40px;
    transform: rotate(6deg);
}

.card-3 {
    bottom: 80px;
    left: 80px;
    transform: rotate(3deg);
}

.card-4 {
    bottom: 160px;
    right: 80px;
    transform: rotate(-3deg);
}

/* Hotel card specific styles */
.hotel-card {
    display: flex;
    align-items: center;
    gap: 8px;
}

.hotel-image img {
    max-width: 64px;
    max-height: 64px;
    background-color: #e6f0ff;
    border-radius: 4px;
    object-fit: contain;

}

.hotel-info {
    display: flex;
    flex-direction: column;
}

.hotel-name {
    font-size: 14px;
    font-weight: 600;
}

.hotel-rating {
    color: #ffd700;
    font-size: 12px;
}

@media (max-width: 768px) {
    .floating-card {
        display: none;
    }

    .demo-button {
        font-size: 10px;

    }

    .content p {
        font-size: 11px;
    }
}