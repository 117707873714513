/* Modal background overlay */
.demo-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 15px;
    box-sizing: border-box;
}

/* Modal container */
.demo-modal-container {
    position: relative;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 900px;
    overflow: hidden;
    max-height: 90vh;
}

/* Close button */
.demo-modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    color: #aaa;
    z-index: 10;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.demo-modal-close:hover {
    color: #666;
}

/* Modal content wrapper */
.demo-modal-content {
    display: flex;
    flex-direction: row;
    max-height: 90vh;
}

/* Left side of modal */
.demo-modal-left {
    flex: 0 0 40%;
    padding: 40px 30px;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: white;
}

/* Right side of modal */
.demo-modal-right {
    flex: 0 0 60%;
    background-color: #EBF5FF;
    padding: 40px 30px 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow-y: auto;
    min-height: 550px;
    position: relative;
}

/* Title styling */
.demo-modal-title {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #1a73e8 !important;
    margin-top: 0 !important;
    margin-bottom: 30px !important;
    line-height: 1.3 !important;
}

/* Form group */
.demo-form-group {
    margin-bottom: 20px;
}

/* Form inputs */
.demo-form-input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-size: 15px;
    outline: none;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #333;
}

.demo-form-input::placeholder {
    color: #999;
}

.demo-form-input:focus {
    border-color: #1a73e8;
    box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.1);
}

/* Phone input container */
.demo-phone-input-container {
    display: flex;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    overflow: hidden;
}

/* Country code */
.demo-country-code {
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: #f9f9f9;
    border-right: 1px solid #e0e0e0;
    font-size: 15px;
    min-width: 70px;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    color: #333;
    user-select: none;
}

.demo-country-code:hover {
    background-color: #f0f0f0;
}

.demo-country-code span {
    margin-right: 5px;
}

.demo-chevron-down,
.demo-chevron-up {
    transition: transform 0.2s ease;
}

/* Country dropdown */
.demo-country-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 20;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 5px;
}

.demo-country-option {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.demo-country-option:hover {
    background-color: #f5f5f5;
}

/* Phone input */
.demo-phone-input {
    flex: 1;
    padding: 12px 15px;
    border: none;
    font-size: 15px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #333;
}

.demo-phone-input::placeholder {
    color: #999;
}

/* Submit button */
.demo-submit-button {
    width: 100%;
    background-color: #1a73e8;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 14px 12px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-top: 10px;
    box-shadow: 0 2px 5px rgba(26, 115, 232, 0.2);
}

.demo-submit-button:hover {
    background-color: #1557b0;
    box-shadow: 0 4px 8px rgba(26, 115, 232, 0.3);
}

.demo-submit-button:active {
    background-color: #0c3d84;
    box-shadow: 0 1px 3px rgba(26, 115, 232, 0.2);
}

/* Benefits section */
.demo-benefits {
    margin-bottom: 20px;
}

/* Benefit items */
.demo-benefit-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.demo-check-icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.demo-benefit-item span {
    font-size: 14px;
    color: #444;
    line-height: 1.4;
}

/* Dashboard preview */
.demo-dashboard-preview {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto;
    width: 100%;
    position: relative;
    right: -30px;
    bottom: -10px;
}

.demo-dashboard-image {
    width: 100%;
    max-width: 550px;
    height: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .demo-modal-content {
        flex-direction: column;
    }

    .demo-modal-left,
    .demo-modal-right {
        flex: none;
        width: 100%;
        padding: 30px 20px;
    }

    .demo-modal-right {
        min-height: auto;
        padding-bottom: 0;
    }

    .demo-modal-title {
        font-size: 20px !important;
        margin-bottom: 20px !important;
    }

    .demo-form-group {
        margin-bottom: 15px;
    }

    .demo-dashboard-preview {
        margin-top: 20px;
        justify-content: center;
        right: -20px;
        bottom: -10px;
    }

    .demo-dashboard-image {
        width: 100%;
        max-width: none;
        border-radius: 8px 0 0 0;
    }
}

@media (max-width: 480px) {
    .demo-modal-overlay {
        padding: 10px;
    }

    .demo-modal-container {
        border-radius: 12px;
        max-height: 95vh;
    }

    .demo-modal-left,
    .demo-modal-right {
        padding: 25px 15px;
    }

    .demo-modal-right {
        padding-bottom: 0;
    }

    .demo-modal-title {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .demo-form-input,
    .demo-phone-input,
    .demo-country-code {
        font-size: 15px;
    }

    .demo-submit-button {
        padding: 12px;
    }

    .demo-benefit-item {
        margin-bottom: 12px;
    }

    .demo-benefit-item span {
        font-size: 14px;
    }

    .demo-check-icon svg {
        width: 18px;
        height: 18px;
    }

    .demo-dashboard-preview {
        right: -15px;
    }

    .demo-country-dropdown {
        position: fixed;
        width: 150px;
        left: 50%;
        transform: translateX(-50%);
    }
}

/* Fix for iOS input zoom */
@media screen and (-webkit-min-device-pixel-ratio: 0) {

    select,
    textarea,
    input[type="text"],
    input[type="tel"],
    input[type="email"] {
        font-size: 16px;
    }
}

/* Handle modal on very small screens */
@media (max-height: 550px) {
    .demo-modal-container {
        max-height: 100vh;
        border-radius: 0;
    }

    .demo-modal-overlay {
        padding: 0;
    }
}