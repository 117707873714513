.testimonials-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 64px 20px;
}

.testimonials-title {
    text-align: center;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 48px;
    line-height: 1.2;
}

.text-blue {
    color: #0066ff;
}

.testimonials-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    perspective: 1000px; /* Only for desktop */
}

/* Card container for 3D flipping */
.testimonial-card-container {
    height: 450px; 
    transition: transform 0.3s ease;
    position: relative; /* For positioning front/back */
    cursor: pointer;
}

.testimonial-card-container:hover {
    transform: translateY(-10px);
}

/* 3D card inner container - Only used for desktop */
.testimonial-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

/* Hover effect for flipping - Only applied on non-touch devices */
@media (hover: hover) {
    .testimonial-card-container:hover .testimonial-card-inner {
        transform: rotateY(180deg);
    }
}

/* Front and back card sides */
.testimonial-card-front,
.testimonial-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    padding: 32px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

/* Back side is rotated only on desktop */
@media (hover: hover) {
    .testimonial-card-back {
        transform: rotateY(180deg);
    }
}

/* Card background colors */
.light-green {
    background: linear-gradient(206deg, rgba(253, 255, 214, 0.50) 4.48%, rgba(254, 255, 251, 0.50) 102.57%);
}

.light-pink {
    background-color: rgba(253, 242, 248, 0.6);
}

.light-purple {
    background-color: rgba(245, 243, 255, 0.6);
}

/* Client logo styles */
.client-logo {
    height: 40px;
    object-fit: contain;
    margin-bottom: 24px;
}

/* Stats styles */
.stats-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex-grow: 1;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.stat-percentage {
    font-size: 28px;
    font-weight: 700;
    color: #111827;
}

.stat-label {
    color: #6C6D6D;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

/* Testimonial quote styles */
.testimonial-quote {
    margin: 24px 0;
    font-size: 14px;
    line-height: 1.6;
    color: #4B5563;
    flex-grow: 1;
}

/* Author styles */
.testimonial-author {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 16px;
}

.author-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.author-details {
    display: flex;
    flex-direction: column;
}

.author-name {
    font-weight: 600;
    color: #111827;
    font-size: 14px;
}

.author-role {
    color: #6B7280;
    font-size: 12px;
}

/* Flip prompt */
.flip-prompt {
    text-align: center;
    margin-top: 16px;
    font-size: 12px;
    color: #6B7280;
    font-style: italic;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .testimonials-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 640px) {
    .testimonials-grid {
        grid-template-columns: 1fr;
    }

    .testimonials-title {
        font-size: 24px;
    }
    
    .testimonial-card-container {
        /* height: auto; */
        min-height: 380px;
    }
}

/* Touch device specific styles - Completely different approach */
@media (hover: none) {
    /* Reset 3D properties which cause the blur */
    .testimonial-card-inner {
        transform-style: flat;
        transition: none;
    }
    
    /* Front and back faces use a fade effect instead of rotation */
    .testimonial-card-front,
    .testimonial-card-back {
        backface-visibility: visible;
        transition: opacity 0.4s ease;
        transform: none !important; /* Override any rotation */
    }
    
    /* Hide the back by default */
    .testimonial-card-back {
        opacity: 0;
        pointer-events: none;
    }
    
    /* Show back on touch and hold */
    .testimonial-card-container:active .testimonial-card-front {
        opacity: 0;
        pointer-events: none;
    }
    
    .testimonial-card-container:active .testimonial-card-back {
        opacity: 1;
        pointer-events: auto;
    }
    
    /* Change prompt text */
    .flip-prompt:after {
        /* content: " (tap and hold)"; */
    }
}

/* JavaScript fallback for older browsers */
.js-flipped .testimonial-card-front {
    opacity: 0;
    pointer-events: none;
}

.js-flipped .testimonial-card-back {
    opacity: 1;
    pointer-events: auto;
    transform: none !important;
}