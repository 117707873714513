.integration_title {
    font-size: 60px;
    line-height: 1.1;
    text-align: center
}

@media screen and (max-width: 768px) {
    .integration_title {
        font-size: 32px;
    }

    .integration_description {
        font-size: 12px !important;
    }

    .integrations-container {
        margin-top: 4rem !important;
        grid-template-columns: repeat(4, 1fr) !important;
    }

    .int_logo {
        /* height: 60px !important;  */
    }
}
