.paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-top: 1px solid #e5e7eb;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .paginationArrow {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(83, 88, 98, 1);
    background: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .paginationArrow:hover:not(:disabled) {
    color: #2966FF;
  }
  
  .paginationArrow:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .arrowIcon {
    flex-shrink: 0;
  }
  
  .pageNumbers {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .pageNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 14px;
    color: #6b7280;
    background: transparent;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .pageNumber:hover:not(.active) {
    background-color: #f3f4f6;
    color: #111827;
  }
  
  .pageNumber.active {
    background-color: #f3f4f6;
    color: #111827;
    font-weight: 600;
    border-radius: 50%;
  }
  
  .ellipsis {
    width: 36px;
    text-align: center;
    font-size: 14px;
    color: #6b7280;
  }
  
  @media (max-width: 640px) {
    .pageNumbers {
      gap: 2px;
    }
    
    .pageNumber {
      width: 32px;
      height: 32px;
    }
    
    .paginationArrow {
      padding: 6px 8px;
    }
  }