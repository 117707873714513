.ais_card {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 12px 40px 0px rgba(6, 11, 19, 0.04), 0px 0px 0px 4px #FFF;
    padding: 24px;
    border: 1px solid #C8D8FF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.ais_card_container {
    display: flex;
    gap: 12px;
}

@media screen and (max-width:768px) {
    .ais_card_container {
        flex-direction: column;
    }

    .ais_sec_title {
        font-size: 32px !important;
    }

    .ais_sec_desc {
        font-size: 12px !important;
    }
}

.ais_card_icon {
    border-radius: 999px;
    background: #E4F2FF;
    display: flex;
    width: 64px;
    height: 64px;
    padding: 17.09px 17px 16.91px 17px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.ais_card_title {
    color: #000;
    text-align: center;
    font-size: 21.938px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.8px;
    /* 131.282% */
    letter-spacing: -0.47px;
}

.ais_card_description {
    color: #535862;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.8px;
    /* 183.077% */
    letter-spacing: -0.09px;
}