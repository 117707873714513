.hzl-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 50;
    padding: 8px 16px;
}

.hzl-container {
    max-width: 1280px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hzl-logo-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.hzl-logo-icon {
    width: 32px;
    height: 32px;
    color: #4f46e5;
}

.hzl-brand-name {
}

.hzl-menu-btn {
    padding: 8px;
    border-radius: 8px;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.hzl-menu-btn:hover {
    /* background-color: #f3f4f6; */
}

.hzl-menu-icon {
    width: 24px;
    height: 24px;
    color: #1f2937;
}

.hzl-dropdown {
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 40;
    max-height: 400px;
    overflow-y: auto;
}

.hzl-dropdown.open {
    transform: translateY(0);
}

.hzl-menu-content {
    max-width: 1280px;
    margin: 0 auto;
    padding: 24px 16px;
}

.hzl-menu-grid {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 32rem;
    margin: 0 auto;
}

.hzl-menu-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 6px;
    color: #4b5563;
    text-decoration: none;
    border-radius: 8px;
    transition: all 0.2s;
}

.hzl-menu-item:hover {
    background-color: #f3f4f6;
    color: #1f2937;
}

.hzl-menu-item svg {
    width: 20px;
    height: 20px;
}

.hzl-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    z-index: 30;
}

.hzl-overlay.visible {
    opacity: 1;
    pointer-events: auto;
}

.hzl-overlay.hidden {
    opacity: 0;
    pointer-events: none;
}

.hzl-main {
    min-height: 100vh;
    background-color: #f3f4f6;
    padding: 96px 32px 32px 32px;
}