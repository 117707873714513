.blog_header {
  background: linear-gradient(180deg, rgba(240, 255, 250, 0) 35.32%, #FFFFFF 86.22%);
  width: 100%;
  
  display: flex;
  align-items: flex-end;
}

.blog_container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 0 16px;
}

.blog_heading_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  margin-top: 14%;
}

.sub_heading {
  color: #2966FF;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
}

.blog_heading {
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -2%;
  color: #181D27;
  word-wrap: break-word;
}

.blog_tag {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0%;
  color: #535862;
}

.blog_cards {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
}

/* Responsive styles */
@media (max-width: 1200px) {

    .blog_heading_box {
        margin-top: 20%;
        }

  .blog_container {
    max-width: 1000px;
  }
  
  .blog_heading {
    font-size: 42px;
    line-height: 52px;
  }
  
  .blog_tag {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (max-width: 992px) {
  /* .blog_header {
    height: 200px;
  } */

  .blog_heading_box {
    margin-top: 20%;
    }
  
  .blog_container {
    gap: 48px;
  }
  
  .blog_heading {
    font-size: 36px;
    line-height: 46px;
  }
  
  .blog_tag {
    font-size: 17px;
    line-height: 26px;
  }
}

@media (max-width: 768px) {
  /* .blog_header {
    height: 180px;
  } */

  .blog_heading_box {
    margin-top: 20%;
    }
  
  .blog_container {
    width: 95%;
    gap: 40px;
  }
  
  .blog_heading_box {
    gap: 10px;
  }
  
  .sub_heading {
    font-size: 15px;
    line-height: 22px;
  }
  
  .blog_heading {
    font-size: 32px;
    line-height: 40px;
  }
  
  .blog_tag {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 576px) {
  /* .blog_header {
    height: 160px;
  } */

  .blog_heading_box {
    margin-top: 20%;
    }

  
  .blog_container {
    width: 100%;
    gap: 32px;
    padding: 0 20px;
  }
  
  .blog_heading_box {
    gap: 8px;
  }
  
  .sub_heading {
    font-size: 14px;
    line-height: 20px;
  }
  
  .blog_heading {
    font-size: 28px;
    line-height: 36px;
  }
  
  .blog_tag {
    font-size: 15px;
    line-height: 22px;
  }
}

@media (max-width: 480px) {
  /* .blog_header {
    height: 140px;
  }
   */
    .blog_heading_box {
        margin-top: 34%;
    }

  .blog_container {
    gap: 24px;
  }
  
  .sub_heading {
    font-size: 13px;
    line-height: 18px;
  }
  
  .blog_heading {
    font-size: 24px;
    line-height: 32px;
  }
  
  .blog_tag {
    font-size: 14px;
    line-height: 20px;
  }
}