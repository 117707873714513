.footer-container {
    background: #0E74BF;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-bottom: 98px; */
    padding-top: 12px;


}

.footer-app {
    /* margin-top: 90px; */
}

.footer_subscribe {
    display: flex;
    padding: 13px 25px;
    gap: 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: rgba(141, 190, 225, 0.50);
    margin: 24px 0;
}

.footer_subscribe button:hover {
    background-color: #003961;

}

@media screen and (max-width: 480px) {
    .footer_subscribe {
        flex-direction: column;
        align-items: start;
    }

    .links-footer {
        font-size: 13px;
        flex-direction: column-reverse;
        gap: 8px !important;
    }

    .footer-links-new {
        width: 100%;
        display: flex;
        justify-content: start !important;
        gap: 4rem;
        /* flex-direction: row; */
    }

    .subscribe_heading {
        font-size: 18px !important;
        text-align: start !important;
        width: 100% !important;
    }

    .footer_subscribe input {
        padding: 0 !important;
        padding-left: 12px !important;
        font-size: 13.333px;
    }
}


.footer_subscribe h1 {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
}

.footer_subscribe input {
    display: flex;
    padding: 14px 29px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #F4F4F4;
}

.footer_subscribe button {
    display: flex;
    padding: 14px 29px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #282E32;
    color: white;
}

.footer_subscribe div {
    display: flex;
    gap: 8px;
}

.links-footer {
    display: flex;
    color: white;
    gap: 42px;
    padding-top: 24px;
    justify-content: space-around;

}

.footer-links-new {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 4rem;
    /* flex-direction: row; */
}

.links-footer li {
    list-style: none;
    margin-bottom: 8px;
}

.section-title-footer {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
}