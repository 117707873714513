* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  text-decoration: none;
  border: 0;
  scroll-behavior: smooth;
  /* list-style: none; */
  /* user-select: none; */
  font-family: var(--volte);
  text-rendering: optimizeSpeed;
}

@font-face {
  font-family: "Volte";
  src: local("Volte"),
    url("./assets/fonts/Volt_Font/Volte-Regular2.otf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Jakarta";
  src: local("Jakarta"),
    url("./assets/fonts/PlusJakarta/plusjakarta.ttf") format("truetype");
  font-display: swap;
}
.open-sans {
  --default-font: "Open Sans", sans-serif;
}
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400, 500, 700;
  font-display: swap;
  src: url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700&display=swap");
}

html {
  scroll-behavior: smooth;
}

body {
  background: white;
  line-height: 1.7;
  color: var(--primary-color);
  font-family: "Jakarta", sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --primary-color: #0078d4;
  --secondary-color: #fff;
  --title-line: #0078d4ab;
  --orange: #e73c00;
  --blueText: #0078d4;
  --blue: #0076d9;
  --green: #16b21a;
  --google-green: #0b650b;
  --light-blue: #dcf3ff;
  --pink: #f9e6e1;
  --gray: #f7f7f7;
  --gray-text: #808080;
  --playfair: "Jakarta", sans-serif;
  --firasans: "Jakarta", sans-serif;
  --firasans-bold: "Jakarta", sans-serif;
  --firasans-reg: "Jakarta", sans-serif;
  --volte-bold: "Jakarta", sans-serif;
  --volte: "Jakarta", sans-serif;
  --roboto: "Jakarta", sans-serif;
}

.title {
  font-family: "Jakarta", sans-serif;
  text-align: center;
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 5px;
  font-weight: 600;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.title::before {
  content: "";
  width: 100%;
  height: 1px;
  background: var(--title-line);
}

.title::after {
  content: "";
  width: 100%;
  height: 1px;
  background: var(--title-line);
}

.whatsapp {
  width: 40px;
  height: 40px;
  background: #fff;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  border-radius: 50%;
  z-index: 100;
  text-align: center;
}

.whatsapp {
  color: #25d366;
}

.DateRangePicker {
  z-index: 20;
}

.carousel.carousel-slider .control-arrow {
  z-index: 0;
}

.carousel .control-dots {
  display: none;
}

.iframeInsert {
  display: block; /* iframes are inline by default */
  height: 100vh; /* Set height to 100% of the viewport height */
  width: 100vw; /* Set width to 100% of the viewport width */
  border: none; /* Remove default border */
  background: lightyellow;
}

.ep-modal-lightbox {
  display: none !important;
}

.sitemaps {
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: #000;
  text-decoration: underline;
}

.sitemaps a {
  color: #000;
}

.errorBoundary {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
  text-align: center;
}

.errorBoundary button {
  padding: 10px;
  border-radius: 8px;
  color: #fff;
  background-color: #0076d9;
  margin-top: 10px;
}

.eapps-instagram-feed-title-container,
.eapps-widget-toolbar {
  display: none !important;
}
