.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  gap: 16px;
}

.container::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
}

.categoriesWrapper {
  width: 100%;
  max-width: calc(100% - 340px);
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.categoriesWrapper::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.categories {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 32px;
  min-width: max-content;
}

.categoryItem {
  position: relative;
  padding-bottom: 8px;
  white-space: nowrap;
}

.categoryLink {
  color: #717680;
  text-decoration: none;
  padding: 8px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  transition: color 0.2s ease;
}

.categoryLink:hover {
  color: #2966FF;
}

.categoryLink:hover::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #7c4dff;
  opacity: 0.5;
  z-index: 2;
}

.active {
  color: #2966FF;
}

.active::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #7c4dff;
  z-index: 2;
}

.sortContainer {
  position: relative;
  width: 320px;
  flex-shrink: 0;
}

.sortDropdown {
  appearance: none;
  width: 100%;
  height: 44px;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 10px 14px;
  font-size: 14px;
  cursor: pointer;
  color: #374151;
}

.chevronIcon {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #6b7280;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Mobile Category Dropdown Styles */
.mobileCategories {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  z-index: 10;
}

.mobileCategorySelector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  color: #2966FF;
}

.dropdownArrow {
  transition: transform 0.2s ease;
}

.dropdownArrow.open {
  transform: rotate(180deg);
}

.mobileCategoriesList {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 20;
  overflow: hidden;
}

.mobileCategoryItem {
  width: 100%;
}

.mobileCategoryLink {
  width: 100%;
  text-align: left;
  padding: 12px 16px;
  background: transparent;
  border: none;
  font-size: 14px;
  color: #717680;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: block;
}

.mobileCategoryLink:hover {
  background-color: #f3f4f6;
}

.activeItem {
  color: #2966FF;
  background-color: #eef2ff;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .sortContainer {
    width: 250px;
  }
  
  .categoriesWrapper {
    max-width: calc(100% - 270px);
  }
  
  .categories {
    gap: 24px;
  }
  
  .categoryLink {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 12px;
  }
  
  .container::before {
    width: 100%;
  }
  
  .sortContainer {
    width: 100%;
    order: 1;
  }
}

@media (max-width: 480px) {
  .mobileCategorySelector,
  .mobileCategoryLink {
    font-size: 13px;
    padding: 10px 14px;
  }
  
  .sortDropdown {
    height: 40px;
    font-size: 13px;
  }
}