.about-us-container {
    border-radius: 8px;
    background: #023F88;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.journey_title {
    color: #FFF;
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 32px;
    margin-bottom: 32px;
    /* 46.667% */
}

.aboutus_description_l {
    font-size: 20px;
}

.history-card {
    display: flex;
    height: 300px;
    padding: 34px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: #FFF;
    backdrop-filter: blur(12px);
    width: 70%;
    box-sizing: border-box;
}

@media screen and (max-width: 768px) {
    .history-card {
        flex-direction: column;
        height: auto;
        padding: 24px;
        gap: 16px;
        width: 80%;
    }

    .history-content {
        width: 100% !important;
        gap: 8px !important;
    }

    .journey_title {
        font-size: 32px;
    }

    .ms_title {
        font-size: 24px !important;
    }

    .ms_description {
        font-size: 13px !important;


    }

    .ms_time {
        font-size: 16px !important;
    }

    .aboutus_description_l {
        font-size: 16px;
    }


}

.ms_time {
    color: #DE9923;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    margin: 0;
}

.ms_title {
    color: #023F88;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    margin: 0;
}

.ms_description {
    color: #494848;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.img_milestone {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    /* width: 40%; */
    /* Fixed width for the image section */
    height: 100%;
    overflow: hidden;
    /* Hide overflow if images are too large */
}

.img_milestone img {
    width: 100%;
    /* height: 100%; */
    object-fit: contain;
}

.milestone_img {
    width: 40%;
    /* Fixed width for single images */
    height: 100%;
    object-fit: contain;
}