.faq-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.faq-title {
    font-size: 48px;
    text-wrap: nowrap;
}

@media screen and (max-width: 480px) {
    .faq-title {
        text-wrap: wrap;
        font-size: 32px;
        text-align: center;
    }

    .accordion-header {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .accordion-content {
        font-size: 13px;
    }
}

.accordion {
    overflow: hidden;
    margin-top: 32px;
    /* background: #EAF9FF; */
}

.accordion-item {
    /* padding: 10px 23px; */
    width: 100%;
    border-radius: 12px;
    border: 1px solid #1C87DA;
    /* background: #FFF; */
    margin-bottom: 12px;
    /* background: #EAF9FF; */
}

.accordion-header {
    width: 100%;
    padding: 15px 23px;
    text-align: left;

    color: black !important;
    height: 84px;
    /* background-color: white; */
    /* background: #EAF9FF; */
    /* background: #EAF9FF; */
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;

    font-style: normal;
    font-weight: 500;
    gap: 2rem;
}

.accordion-header:hover {
    /* background-color: #e2e2e2; */
}

.accordion-content {
    /* display: none; */
    padding: 15px;
    /* background-color: #fff;
    background: #EAF9FF; */
    border-radius: 12px;
    color: #22518A;
    padding: 10px 23px;
    transition: max-height 0.3s ease;
}

.accordion-content p {
    margin: 0;
    /* background: #EAF9FF; */
}

.icon_acc {
    font-size: 18px;
    transition: transform 0.3s ease;
}

.accordion-header[aria-expanded="true"] .icon_acc {
    transform: rotate(45deg);
    /* Rotates the plus to form an 'x' */
}