.landing-container {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Use viewport height instead of percentage */
    background: linear-gradient(135deg, #edf1f7 0%, #e6edf8 100%);
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./svgs/hero.svg');
    background-size: cover;
    background-position: center;
    opacity: 1;
    z-index: 0;
}

.background-image_footer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./svgs/footer.svg');
    background-size: cover;
    background-position: center;
    opacity: 1;
    z-index: 0;
}



.main-container {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;

}

.main-container_footer {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;

}

.content {
    text-align: center;

    /* max-width: 800px; */
}

.content h1 {
    font-size: 74px;
}

.content_footer {
    text-align: center;
    /* text-wrap: nowrap; */
}

.content_footer h1 {
    font-size: 64px;
}


.hero-title {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 24px;
    color: #333;
}

.hero-title-blue {
    color: #2b87de;
}

.hero-description {
    font-size: 18px;
    line-height: 1.6;
    color: #666;
    margin-bottom: 32px;
}

.demo-button {
    display: inline-flex;
    align-items: center;
    background-color: #2b87de;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 24px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.demo-button:hover {
    background-color: #1a76c6;
    transform: translateY(-2px);
}

.button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    margin-left: 8px;
}

@media (max-width: 768px) {
    .hero-title {
        font-size: 36px;
    }

    .content h1 {
        font-size: 36px;
    }

    .content_footer h1 {
        font-size: 28px;
    }


    .hero-description {
        font-size: 16px;
    }

    .landing-container {
        /* height: 50vh; */
        height: auto;
        padding: 4rem 0;
    }

    .background-image {
        background-image: url('./svgs/heromobile.svg');
    }

    .background-image_footer {

        background-image: url('./svgs/footermobile.svg');

    }

    .main-container_footer {
      
        align-items: flex-end;
     

    }


}