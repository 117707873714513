.client-logos-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    display: grid;
    grid-template-rows: auto 1fr auto;
    /* gap: 40px; */
}

.logo_container {
    border: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    width: 100%
}

.logo_right {
    width: 290px;

}

/* Grid layouts */
.logo-grid {
    display: grid;
    /* gap: 24px; */
    align-items: center;
}

.top-grid,
.bottom-grid {
    grid-template-columns: repeat(4, 1fr);
    border-bottom: 1px solid #eee;
    /* padding-bottom: 24px; */
}

.left-grid,
.right-grid {
    grid-template-rows: repeat(3, 1fr);
    /* padding: 24px 0; */
}

/* Center content styles */
.center-content {
    text-align: center;
    /* padding: 60px 0; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: end;
}

.trust-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
    /* margin-bottom: 32px; */
    color: #1a1a1a;
    margin-top: auto;
}

.text-blue {
    color: #0066ff;
}

/* Client logo styles */
.client-logoi {
    max-width: 120px;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    /* filter: grayscale(100%); */
    /* opacity: 0.7; */
    /* transition: all 0.3s ease; */
}

.client-logo:hover {
    filter: grayscale(0%);
    opacity: 1;
}

.logo-grid-content {
    display: flex;
    flex-direction: row;
}

/* Demo button styles */
.demo-button-2 {
    background: linear-gradient(91deg, #4596F6 0.25%, #1469D0 100.07%);
    cursor: pointer;
    color: white;
    border: none;
    padding: 16px 48px;
    /* border-radius: 4px; */
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    height: 100px;
    margin-top: auto;
}

.demo-button:hover {
    background-color: #0052cc;
}

/* "This can be you" styles */
.can-be-you {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 2px dashed #0066ff;
    border-radius: 4px;
    color: #0066ff;
    font-weight: 600;
    font-size: 14px;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .client-logos-container {
        grid-template-columns: 1fr;
    }

    .left-grid,
    .right-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
    }

    .top-grid,
    .bottom-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .client-logo {
        max-width: 100px;
    }

    .trust-title {
        font-size: 28px;
    }
}

@media (max-width: 640px) {

    .top-grid,
    .bottom-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .client-logo {
        max-width: 80px;
    }

    .trust-title {
        font-size: 24px;
    }
}